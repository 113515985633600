<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body" :style="intraction">
        <!-- BOC:[error] -->
        <ApiErrorDialog v-if="api.isError" :api="api" />
        <!-- EOC -->
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            All Awarded Drawings
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="shop-panel">
            <v-img
              :src="require('@/assets/explore/menu/studio.png')"
              max-width="80"
              class="ms-2 d-flex justify-center align-end text-center text-stroke-md"
              style="cursor: pointer"
              @click="studio()"
            >
              {{ $t("title.studio") }}
            </v-img>
          </div>
          <div>
            <div class="items-panel pa-3">
              <div>
                <div>
                  <v-row class="mx-3">
                    <v-col
                      class="px-1 py-3"
                      cols="4"
                      @click="viewImage(i)"
                      v-for="(item, i) in slicedData"
                      :key="i"
                    >
                      <v-card
                        flat
                        tile
                        style="
                          border: 5px solid #cca06d;
                          height: 100px;
                          width: 100px;
                        "
                        class="d-flex align-center"
                      >
                        <div
                          style="
                            position: absolute;
                            z-index: 99;
                            top: 0;
                            right: -5px;
                            text-align: end;
                          "
                        >
                          <v-img
                            contain
                            max-width="40"
                            class="mx-auto"
                            v-if="
                              item.GalleryAward && item.GalleryAward.imageUrl
                            "
                            :src="item.GalleryAward.imageUrl"
                          ></v-img>
                        </div>
                        <v-img
                          :src="item.imageUrl"
                          contain
                          max-height="90"
                          class="mx-auto"
                          style="z-index: 90; cursor: pointer"
                        >
                        </v-img>
                        <!-- <div class="font-weight-black text-center pt-2">
                          {{ item.name }}
                        </div> -->
                      </v-card>
                    </v-col>
                   
                  </v-row>
                </div>
              </div>
             <div class="mt-8 d-flex align-center justify-center" v-if="slicedData.length == 0">No Drawings</div>
            </div>
            <div class="text-center mt-5" v-if="showAllBtn">
                <v-btn small color="yellow" @click="showAll ?seeLess(): seeAll()">
                  {{ showAll ? $t("string.show_less") : $t("string.show_all") }}
                </v-btn>
              </div>
            <RewardDialog
              v-if="bought"
              :callbackClose="exitRwd"
              :showDialog="true"
              :rewards="rewards"
            />
          </div>

          <v-card class="save-confirm" v-if="isView">
            <div class="view-image mx-auto">
              <div class="text-right pa-3">
                <CloseBtn size="40" :callback="closeView" />
              </div>
              <div class="d-flex align-center">
                <div style="max-height: 70vh; width: 100%">
                  <div class="font-weight-black text-center">
                    {{ $t("string.theme") }}
                  </div>
                  <div
                    class="font-weight-black text-center text-stroke-black-sm"
                  >
                    {{
                      JSON.parse(
                        slicedData[currentImage].GalleryTheme.translatableName
                      )[$_getLocale()]
                    }}
                  </div>
                  <v-img
                    :src="slicedData[currentImage].imageUrl"
                    contain
                    class="mx-auto"
                    style="max-height: 60vh; width: 100%"
                  >
                  </v-img>
                  <div
                    style="
                      z-index: 1 !important;
                      background-color: white;

                      max-width: 250px;
                    "
                    class="mx-auto mt-3 d-flex"
                  >
                    <div class="text-right pa-1">
                      <v-img
                        v-if="
                          slicedData[currentImage].GalleryAward &&
                          slicedData[currentImage].GalleryAward.imageUrl
                        "
                        contain
                        max-width="40"
                        :src="slicedData[currentImage].GalleryAward.imageUrl"
                      ></v-img>
                    </div>
                    <div class="mx-auto">
                      <div class="font-weight-black text-center pt-2">
                        {{ transformName(auth.User.name) }}
                    </div>
                    <div
                      v-if="auth.Institution"
                      class="text-subtitle-2 text-center text--secondary"
                    >
                      {{ auth.Institution.name }}<br />{{ auth.Classroom.name }}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
  
  <script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    coin: (state) => state.coin.data,
    tutorial: (state) => state.tutorial.data,
  }),
  components: {
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/games/RewardDialog"
      ),
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  data: () => ({
    isView: false,
    showAllBtn: false,
    showAll: false,
    currentImage: 0,
    image: null,
    imageUrl: null,
    rewards: [],
    bought: false,
    currentPage: 1,
    totalPage: 0,
    selectedItem: null,
    studentAvatar: {},
    intraction: null,
    items: [],
    itemData: [],
    slicedData: [],
    myDrawing: {},
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },

    //EOC
    avatar: {},
    authData: [],
    theme: {},
  }),
  created() {
    this.avatar = this.$_.cloneDeep(JSON.parse(this.auth.Player.avatar));
    this.studentAvatar = this.$_.cloneDeep(this.auth.Player.avatar);
    this.api.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/studio/all";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.authData = this.$_.clone(this.auth);
      this.api.isLoading = false;
      this.items = resp.GalleryAwardDrawing;
      this.totalPage = Math.ceil(Object.keys(this.items).length / 6);
      if(this.totalPage>1){
        this.showAllBtn = true
      }
      this.slicedData = this.paginateData(this.items, 6)[this.currentPage - 1];
    };
  },
  watch: {
    image: function (val) {
      this.image = val;
      if (val) {
        this.imageUrl = URL.createObjectURL(val);
      } else {
        this.imageUrl = null;
      }

      this.callbackPreview(this.url);
    },
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    viewImage(i) {
      this.isView = true;
      this.currentImage = i;
    },
    closeView() {
      this.isView = false;
    },
    nextImage() {
      this.currentImage++;
    },
    preImage() {
      this.currentImage--;
    },
    studio() {
      this.$router.push({
        name: "PageHomeStudio",
      });
    },
    openFileInput() {
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      // Handle the uploaded file (e.g., display preview, upload to server, etc.)
      console.log("Uploaded file:", file);
    },
    paginateData(data, pageSize) {
      const result = [];
      const totalPages = Math.ceil(data.length / pageSize);

      for (let i = 0; i < totalPages; i++) {
        const start = i * pageSize;
        const end = start + pageSize;
        const page = data.slice(start, end);
        result.push(page);
      }

      return result;
    },
    exit() {
      this.$router.push({
        name: "PageHomeStudio",
      });
    },
    exitRwd() {
      this.bought = false;
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    seeAll() {
      this.showAll = true
      this.slicedData = this.$_.cloneDeep(this.items);
    },
    seeLess() {
      this.showAll = false
      this.slicedData = this.paginateData(this.items, 6)[this.currentPage - 1];
    },
    transformName(name) {
    const words = name.split(' ');
    const firstName = words[0];
    const initials = words.slice(1).map(word => word.charAt(0) + '.');
    const transformedName = [firstName, ...initials].join(' ');
    
    return transformedName;
}
  },
};
</script>
  
  <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.coin-wrapper {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  max-width: 480px !important;
  width: 100%;
  z-index: 1;
}
.coin-panel {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  z-index: 1;
  min-width: 120px;
  margin: 10px;
  right: 0px;
  padding: 2px 10px; /* remove this */
  background-color: #fff;
  text-align: right;
}
.burst {
  width: 100vw;
  height: 100vh;
  background: url("../../../assets/island/Burst.png") center bottom !important;
  background-size: cover !important;
  position: absolute;
  opacity: 0.6;
  z-index: 0;
}
.text-border {
  text-shadow: -1px 1px 0 rgb(255, 255, 255), 1px 1px 0 rgb(255, 255, 255),
    1px -1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255);
  z-index: 3;
}
.item-bar {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 10px;
  margin-top: 10px;
}

.low-item-bar {
  background: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px;
  margin-top: 10px;
}
.avatar {
  height: 140px;
  overflow: hidden;
}
.crop {
  width: 70px;
  height: 30px;
  overflow: hidden;
}

.hat {
  width: 100px;
  height: 100px;
  margin: -10px 0 0 -10px;
}
.avatar-hat {
  position: absolute;
}

@media (max-height: 500px) {
  #shop {
    background-size: auto 500px !important;
  }
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.text-stroke-black-sm {
  color: #fff;
  text-shadow: #000000 3px 0px 0px, #000000 2.83487px 0.981584px 0px,
    #000000 2.35766px 1.85511px 0px, #000000 1.62091px 2.52441px 0px,
    #000000 0.705713px 2.91581px 0px, #000000 -0.287171px 2.98622px 0px,
    #000000 -1.24844px 2.72789px 0px, #000000 -2.07227px 2.16926px 0px,
    #000000 -2.66798px 1.37182px 0px, #000000 -2.96998px 0.42336px 0px,
    #000000 -2.94502px -0.571704px 0px, #000000 -2.59586px -1.50383px 0px,
    #000000 -1.96093px -2.27041px 0px, #000000 -1.11013px -2.78704px 0px,
    #000000 -0.137119px -2.99686px 0px, #000000 0.850987px -2.87677px 0px,
    #000000 1.74541px -2.43999px 0px, #000000 2.44769px -1.73459px 0px,
    #000000 2.88051px -0.838247px 0px;
}
.text-stroke-md {
  color: #fff;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.text-stroke-red-md {
  color: #fff;
  text-shadow: #ff0000 3px 0px 0px, #ff0000 2.83487px 0.981584px 0px,
    #ff0000 2.35766px 1.85511px 0px, #ff0000 1.62091px 2.52441px 0px,
    #ff0000 0.705713px 2.91581px 0px, #ff0000 -0.287171px 2.98622px 0px,
    #ff0000 -1.24844px 2.72789px 0px, #ff0000 -2.07227px 2.16926px 0px,
    #ff0000 -2.66798px 1.37182px 0px, #ff0000 -2.96998px 0.42336px 0px,
    #ff0000 -2.94502px -0.571704px 0px, #ff0000 -2.59586px -1.50383px 0px,
    #ff0000 -1.96093px -2.27041px 0px, #ff0000 -1.11013px -2.78704px 0px,
    #ff0000 -0.137119px -2.99686px 0px, #ff0000 0.850987px -2.87677px 0px,
    #ff0000 1.74541px -2.43999px 0px, #ff0000 2.44769px -1.73459px 0px,
    #ff0000 2.88051px -0.838247px 0px;
}
.items-panel {
  border: #caad87 5px solid;
  background-color: #f7efe3;
  margin-top: 95px !important;
  padding-bottom: 30px !important;
}
.shop-panel {
  z-index: 1;
  margin: 10px;
  left: 0px;
  position: absolute;
}
.pannel-text {
  z-index: 1;
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
}
.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 60px 0 0px 0;
  /* background-color: #ffb8b8; */
  position: relative;
}

.view-image {
  max-width: 480px;
  min-height: 100%;
  /* background-color: #ffb8b8; */
  position: relative;
}

.main-body {
  /* background-color: #ffb8b8; */
  background-color: rgba(255, 71, 123, 0.3);
}

.save-confirm {
  position: fixed;
  background-color: #ffb8b8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

#shop {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.buy-confirm {
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.selected-chest {
  background-color: #8b572977;
  border-radius: 30px;
}

#shop-app {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.shop-plot {
  background: rgba(0, 0, 0, 0.2);
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 15px;
  border-radius: 10px;
}

.welcome-enter-active {
  animation: welcome-in 0.5s;
}
.welcome-leave-active {
  animation: welcome-out 0.5s reverse;
}
@keyframes welcome-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes welcome-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.low_graphics {
  background: none !important;
}

.low_island_plot {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px;
  /* padding: 20px !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_title_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  padding: 5px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin: 20px;
}
.low_island_building_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_coin_plot {
  background-color: rgb(246, 246, 246) !important;
  width: 100%;
  margin-right: 10px;
  padding: 5px 2%;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.low_plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}
.plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.disabled-item-image {
  filter: brightness(0%) contrast(10%) !important;
}

.image-container {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Ensure it takes the full width */
  max-width: 480px; /* Limit its width if needed */
  z-index: 9999;
}
</style>